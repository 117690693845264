import Link from '@components/common/link';
import { MdErrorOutline } from 'react-icons/md';
import Button from '@components/common/button';

export default function PageNotFound() {
  return (
    <div className="  flex flex-1 items-center justify-center flex-col">
      <MdErrorOutline className="w-16 h-16 lg:w-19 lg:h-19 text-topaz" />
      <h1 className="text-display mt-6">Oops!</h1>
      <h1 className="text-display text-center">Page not found.</h1>
      <Link className="text-white font-medium  lg:block" href="/">
        <Button size="lg" className="px-12 mt-12">
          Go to Home
        </Button>
      </Link>
    </div>
  );
}
